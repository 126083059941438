import { Action } from "redux"
import persistReducer from "redux-persist/es/persistReducer"
import storage from "redux-persist/lib/storage"

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetMenu: '[Set Menu] Action',
  GetMenu: '[Get Menu] Action',
}

const initialMenuState: IMenuState = {
  menu: undefined,
}

export interface IMenuState {
  menu?: []
}

export const reducer = persistReducer(
  {storage, key: 'menuAccess', whitelist: ['menu']},
  (state: IMenuState = initialMenuState, action: ActionWithPayload<IMenuState>) => {
    switch (action.type) {
      case actionTypes.SetMenu: {
        const menu = action.payload?.menu
        return {...state, menu}
      }

      case actionTypes.GetMenu: {
        return {...state, menu: undefined}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setMenu: (menu: []) => ({type: actionTypes.SetMenu, payload: {menu}}),
  getMenu: () => ({
    type: actionTypes.GetMenu,
  }),
}