export default function setupAxios(axios: any, store: any) {
  const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:3000'

  axios.defaults.baseURL = API_URL;

  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
