/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import 'leaflet/dist/leaflet.css';
import './maps.css';
import MapsAPI from '../../../api/maps';
import { DefaultTitle } from '../../../_metronic/layout/components/header/page-title/DefaultTitle';
import ProductAPI from '../../../api/product';


const MapsPage: FC = () => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState([])
  const [productData, setProductData] = useState([])
  const [filter, setFilter] = useState({
    productId: ''
  });

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      const params = { productId: filter.productId };
      try {
        const res = await MapsAPI.getList(params);
        if (isMounted) {
          setData(res.data);
          setLoadingData(false);
        }
      } catch (err: any) {
        if (isMounted) {
          console.log(err.message);
        }
      }
    };
  
    if (loadingData) {
      getData();
    }

    return () => {
      isMounted = false;
    };
  }, [loadingData])

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      try {
        const res = await ProductAPI.getList({});
        if (isMounted) {
          setProductData(res.data.data);
        }
      } catch (err: any) {
        if (isMounted) {
          console.log(err.message);
        }
      }
    };
    getData();

    return () => {
      isMounted = false;
    };
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFilter((prev: any) => ({ ...prev, [name]: value }));
    setLoadingData(true);
  };  

  return (
    <>
      <PageTitle breadcrumbs={[]}>Maps</PageTitle>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className='d-flex flex-stack container-fluid'
        >
          <DefaultTitle />
          <div className='d-flex align-items-center py-1'>
            <select
              className='form-select form-select-solid'
              name='productId'
              value={filter.productId}
              onChange={handleChange}
            >
              <option value=''>Semua</option>
              {productData.map((d: any, i: number) => (
                <option value={d.id} key={d.id}>{d.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <MapContainer
        style={{ height: '100%', zIndex: 0 }}
        center={[-6.9782, 107.6453]}
        zoom={8}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup chunkedLoading>
          {data.map((item: any, i: number) => (
            <Marker
              key={i}
              position={[item.lat, item.long]}
            >
              <Popup closeButton={false}>
                <img 
                  src={item.image} 
                  className='mb-3 rounded' 
                  style={{ height: '100px', width: '200px', objectFit:'cover' }}
                  alt={item.name}
                />
                <h6 className='text-center'>
                  {item.name}
                </h6>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </>
  )
}

export {MapsPage}
